
import { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { generateGroupStandings } from "../requests";
import { Match, Team, MatchResultBet } from "../types";
import MatchListItem from "../components/MatchListItem";
interface OverviewProps {
  props: RouteComponentProps;
}

function Overview({ props: RouteComponentProps }: OverviewProps) {


  const [matches, setMatches] = useState<Match[]>([]);
  const [bets, setBets] = useState<MatchResultBet[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [groupStandingTeams, setGroupStandingTeams] = useState<Team[]>([]);
  const [t1Name, setT1Name] = useState<string>("Team 1");
  const [t2Name, setT2Name] = useState<string>("Team 2");
  const [t3Name, setT3Name] = useState<string>("Team 3");
  const [t4Name, setT4Name] = useState<string>("Team 4");

  useEffect(() => {

    var team1Name = "";
    var team2Name = "";
    var team3Name = "";
    var team4Name = "";
    for (var i = 1; i < 5; i++) {
      var teamName = "Team " + i;
      if (localStorage.getItem("teamID_" + i) !== null) {
        teamName = localStorage.getItem("teamID_" + i)!;
      }

    

      if (i === 1) {
        setT1Name(teamName);
        team1Name = teamName;
      } else if (i === 2) {
        setT2Name(teamName);
        team2Name = teamName;
      } else if (i === 3) {
        setT3Name(teamName);
        team3Name = teamName;
      } else if (i === 4) {
        setT4Name(teamName);
        team4Name = teamName;
      }
    }

    const t1: Team = {
      id: "1",
      name: team1Name!,
      points: 0,
      goalDifference: 0,
      goalsScored: 0,
    };
    const t2: Team = {
      id: "2",
      name: team2Name!,
      points: 0,
      goalDifference: 0,
      goalsScored: 0,
    };
    const t3: Team = {
      id: "3",
      name: team3Name!,
      points: 0,
      goalDifference: 0,
      goalsScored: 0,
    };
    const t4: Team = {
      id: "4",
      name: team4Name!,
      points: 0,
      goalDifference: 0,
      goalsScored: 0,
    };
    const m1: Match = { id: "1", homeTeam: t1, awayTeam: t2 };
    const m2: Match = { id: "2", homeTeam: t3, awayTeam: t4 };
    const m3: Match = { id: "3", homeTeam: t1, awayTeam: t3 };
    const m4: Match = { id: "4", homeTeam: t2, awayTeam: t4 };
    const m5: Match = { id: "5", homeTeam: t3, awayTeam: t2 };
    const m6: Match = { id: "6", homeTeam: t4, awayTeam: t1 };

    const b1: MatchResultBet = {
      matchId: "1",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    const b2: MatchResultBet = {
      matchId: "2",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    const b3: MatchResultBet = {
      matchId: "3",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    const b4: MatchResultBet = {
      matchId: "4",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    const b5: MatchResultBet = {
      matchId: "5",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    const b6: MatchResultBet = {
      matchId: "6",
      homeTeamScore: 0,
      awayTeamScore: 0,
    };

    setTeams([t1, t2, t3, t4]);
    setBets([b1, b2, b3, b4, b5, b6]);
    setMatches([m1, m2, m3, m4, m5, m6]);
  }, []);

  const updateTeamName = (teamId: string, newName: string) => {
    let matchesCopy = [...matches];
    let matchesWithUpdatedTeamNames = matchesCopy.map((m) => {
      if (m.homeTeam.id === teamId) {
        return { ...m, homeTeam: { ...m.homeTeam, name: newName } };
      } else if (m.awayTeam.id === teamId) {
        return { ...m, awayTeam: { ...m.awayTeam, name: newName } };
      }
      return m;
    });

    const teamsWithUpdatedNames = teams.map(team => {
        if (team.id === teamId) {
          return {...team, name: newName};
        }
        return team;
    });

    localStorage.setItem("teamID_" + teamId, newName);
    setMatches(matchesWithUpdatedTeamNames);
    setTeams(teamsWithUpdatedNames);
  };

  return <div />;
  /*
  return (
    <div style={{ padding: "20px" }}>
      <h4>Team names</h4>
    
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <div style={{ flex: "1" }}>
              <label>1.</label>
              <input
                type="text"
                value={t1Name}
                onChange={(event) => {
                  setT1Name(event.currentTarget.value);
                }}
                onBlur={(event) => {
                  if (event.currentTarget.value === "") {
                    setT1Name("Team 1");
                    updateTeamName("1", "Team 1");
                    return;
                  }
                  updateTeamName("1", t1Name);
                }}
              />
            </div>
            <div style={{ flex: "1" }}>
              <label>2.</label>
              <input
                type="text"
                value={t2Name}
                onChange={(event) => {
                  setT2Name(event.currentTarget.value);
                }}
                onBlur={(event) => {
                  updateTeamName("2", t2Name);
                }}
              />
            </div>

            <div style={{ flex: "1" }}>
              <label>3.</label>
              <input
                type="text"
                value={t3Name}
                onChange={(event) => {
                  setT3Name(event.currentTarget.value);
                }}
                onBlur={(event) => {
                  updateTeamName("3", t3Name);
                }}
              />
            </div>
            <div style={{ flex: "1" }}>
              <label>4.</label>
              <input
                type="text"
                value={t4Name}
                onChange={(event) => {
                  setT4Name(event.currentTarget.value);
                }}
                onBlur={(event) => {
                  updateTeamName("4", t4Name);
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: "2" }}>
          {matches.map((match, index) => {
            const bet = bets.filter((bet) => bet.matchId === match.id)[0];
            return (
              <MatchListItem
                match={match}
                bet={bet}
                key={index}
                onUpdateBet={(updatedBet: MatchResultBet) => {
                  const betToUpdate = bets.filter(
                    (b) => b.matchId === updatedBet.matchId
                  )[0];
                  const betToUpdateIndex = bets.indexOf(betToUpdate);
                  const newBet = {
                    ...betToUpdate,
                    homeTeamScore: updatedBet.homeTeamScore,
                    awayTeamScore: updatedBet.awayTeamScore,
                  };
                  bets[betToUpdateIndex] = newBet;
                  setBets([...bets]);
                }}
              />
            );
          })}
          <button
            style={{ marginTop: "50px" }}
            onClick={() => {
              generateGroupStandings(matches, bets)
                .then((sortedTeams) => {
                  let mappedTeams: Team[] = sortedTeams.map((sortedTeam) => {
                
                    let mapppedTeam: Team = {
                      ...sortedTeam,
                      name: teams.filter((t) => t.id === sortedTeam.id)[0].name,
                    };
                    return mapppedTeam;
                  });
                
                  setGroupStandingTeams(mappedTeams);
                })
                .catch((e) => {
                });
            }}
          >
            Calculate group standings
          </button>
        </div>
        <div style={{ flex: "2" }}>
          <table>
            <tr>
              <th>Position</th>
              <th>Team</th>
              <th>GD</th>
              <th>GS</th>
              <th>Points</th>
            </tr>
            {groupStandingTeams.map((groupStandingTeam, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <label>{groupStandingTeam.name}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.goalDifference}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.goalsScored}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.points}</label>
                  </td>
                </tr>
              );
            })}
          </table>
          <h4>Currrent sorting</h4>
          <ol>
            <li>
            Number of points
            </li>
            <li>
            2. Versus
            </li>
          
          
            <ul>
              <li>
              Higher number of points obtained in the matches played between the teams in question;
              </li>
              <li>
              Superior goal difference resulting from the matches played between the teams in question;
              </li>
              <li>
              Higher number of goals scored in the matches played between the teams in question;
              </li>
            </ul>
            </ol>
        </div>
      </div>
    </div>
  );
  */
}
  
export default Overview;
