import logo from "./logo.svg";
import "./App.css";
import { Router, Link } from "@reach/router";
import Overview from "./pages/Overview";
import RegisterGroupStage from "./pages/RegisterGroupStage";
import JoinCompetition from "./pages/JoinCompetition";
function App() {
  return (
    <Router basepath="/">
      <Overview path="/" />
      <RegisterGroupStage path="/register-group-stage/:participantId" />
      <JoinCompetition path="/competitions/:competitionId/join" />
    </Router>
  );
}

export default App;
