import { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import {
  generateGroupStandings,
  fetchGropuGamesRegister,
  createParticipantForCompetition,
  createAuthenticatedInstance,
  getCompetitionInvite,
} from "../requests";
import { Competition } from "../types";
import { Button, CircularProgress, TextField, Card } from "@mui/material";
import { useNavigate } from "@reach/router";
import { CheckSquare, Award, Smile } from "react-feather";

interface JoinCompetitionProps extends RouteComponentProps {
  competitionId?: string;
}

function JoinCompetition({ competitionId }: JoinCompetitionProps) {
  const [competitionToJoinId, setCompetitionToJoinId] = useState<string>("");
  const [competition, setCompetition] = useState<Competition>();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();

  const onJoinCompetitionClick = () => {
    if (competitionId) {
      createParticipantForCompetition(competitionId, email, username)
        .then((participantId) => {
          navigate(`/register-group-stage/${participantId}}`);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (competitionId) {
      getCompetitionInvite(competitionId)
        .then((competition) => {
          setCompetition(competition);
        })
        .catch((error) => {});
    }

    if (localStorage.getItem("vm-drommen-participant-id") !== null) {
      if (localStorage.getItem("vm-drommen-participant-id")) {
        createAuthenticatedInstance(
          localStorage.getItem("vm-drommen-participant-id")!
        );
        navigate(
          `/register-group-stage/${localStorage.getItem(
            "vm-drommen-participant-id"
          )}}`
        );
      }
    }
  }, []);

  const currentCompetition = competition ?? null;

  if (!currentCompetition) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "800px",
        width: "100%",
        paddingTop:"100px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "250px",
          flexDirection: "column",
        }}
      >
        
        <h1>Hey, welcome to {currentCompetition.name}</h1>
        <h3>How does this work? This is the setup that your organizer, Daniel, have selected:</h3>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card>
            <div className="competition-step-box">
              <h4>1. Group stage</h4>
              <CheckSquare color="#32213A" size="36" />
              <ul>
                <li>
                  <label>
                    Each participant fill in their best guesses for the gropu
                    stage matches.
                  </label>{" "}
                </li>
                <li>
                  <label>
                    Which team that will advance to the final stage will be
                    calculated automatically based on the results you enter.
                  </label>
                </li>
                <li>
                  <label>
                    You get points for correct match outcome and extra for the
                    correct result.
                  </label>
                </li>
              </ul>
            </div>
          </Card>
          <Card style={{ marginLeft: "50px", marginRight: "50px" }}>
            <div className="competition-step-box">
              <h4>2. Playoffs</h4>

              <Award color="#32213A" size="36" />

              <ul>
                <li>
                  <label>
                    Each participant fill in their best guesses for the gropu
                    stage matches.
                  </label>{" "}
                </li>
                <li>
                  <label>
                    Which team that will advance to the final stage will be
                    calculated automatically based on the results you enter.
                  </label>
                </li>
              </ul>
            </div>
          </Card>
          <Card>
            <div className="competition-step-box">
              <h4>3. Enjoy</h4>

              <Smile color="#32213A" size="36" />
              <ul>
                <li>
                  <label>
                    Which team that will advance to the final stage will be
                    calculated automatically based on the results you enter.
                  </label>
                </li>
                <li>
                  <label>Sit back & enjoy the show</label>
                </li>
              </ul>
            </div>
          </Card>
        </div>

        <Card style={{minWidth:"600px", minHeight:"250px", marginTop:"100px", width:"100%"}}> 
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding:"5px"
          }}
        >

          <h3 style={{ textAlign: "left" }}>
            Ready to get started? Select a username and fill in your email to join the competition.
          </h3>
          <div style={{ display: "flex", flexDirection: "row", marginTop:"30px", justifyContent:"center" }}>
            <div style={{ width: "300px", marginRight: "30px" }}>
              <TextField
                id="input-username"
                label="Username"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
              />
              <label style={{ fontSize: "14px", color: "gray" }}>
                This can be anything you like! This will be named displayed to
                the other participants in your competition
              </label>
            </div>
            <div style={{ width: "300px", marginRight: "30px" }}>
              <TextField
                id="input-emamil"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
              <label style={{ fontSize: "14px", color: "gray" }}>
                We your email as an identifier for your account. You will also
                be able to to sign into other devices (like our mobile app!)
                using this email.
              </label>
            </div>
            <Button
              variant="contained"
              style={{ height: "40px" }}
              onClick={onJoinCompetitionClick}
            >
              Join Competition
            </Button>
          </div>
        </div>
        </Card>

      </div>
    </div>
  );
}

export default JoinCompetition;
