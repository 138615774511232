import { Match, MatchResultBet } from "../types";
import "./components.css";
import { Card } from "@mui/material";

interface MatchListItemProps {
  match: Match;
  bet: MatchResultBet;
  onUpdateBet: (updatedBet: MatchResultBet) => void;
}

export default function MatchListItem({
  match,
  bet,
  onUpdateBet,
}: MatchListItemProps) {
  let homeTeamScore = bet.homeTeamScore;
  let awayTeamScore = bet.awayTeamScore;

  return (

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "250px",
          color: "#383B53",
          fontSize: "18px",
          borderBottom: "1px solid #e3e3e3",
          paddingBottom: "10px",
        }}
      >
        <div style={{ flex: 2, textAlign: "center" }}>
          <label style={{ color: "#000", fontSize: "14px" }}>
            {match.homeTeam.name}
          </label>
          <h2 style={{ margin: "0px", marginBottom: "5px" }}>
            {homeTeamScore}
          </h2>
          <div style={{ flexDirection: "row" }}>
            <button
              className="score-button"
              style={{ marginRight: "5px" }}
              onClick={() => {
                const newBet = { ...bet!, homeTeamScore: homeTeamScore - 1 };
                onUpdateBet(newBet);
              }}
            >
              -
            </button>
            <button
              className="score-button"
              onClick={() => {
                const newBet = { ...bet!, homeTeamScore: homeTeamScore + 1 };
                onUpdateBet(newBet);
              }}
            >
              +
            </button>
          </div>
        </div>
        <div style={{ flex: 2, textAlign: "center" }}>
          <label style={{ color: "#000", fontSize: "14px" }}>
            {match.awayTeam.name}
          </label>
          <h2 style={{ margin: "0px", marginBottom: "5px" }}>
            {awayTeamScore}
          </h2>
          <div style={{ flexDirection: "row" }}>
            <button
              className="score-button"
              style={{ marginRight: "5px" }}
              onClick={() => {
                const newBet = { ...bet!, awayTeamScore: awayTeamScore - 1 };
                onUpdateBet(newBet);
              }}
            >
              -
            </button>
            <button
              className="score-button"
              onClick={() => {
                const newBet = { ...bet!, awayTeamScore: awayTeamScore + 1 };
                onUpdateBet(newBet);
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>
  );
}
