import SkillBlockItem from "../components/SkillBlockItem";
import AddSkillBlock from "../components/AddSkillBlock";
import SkillBlockPosition from "../components/SkillBlockPosition";
import { SkillBlock, ProgressStatusListItem } from "../types";
import TextField from "./TextField";
import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Circle } from "react-feather";
interface PublishChangesRowProps {}


const listItems: ProgressStatusListItem[] = [{title: "Group Stage", info: "Register results for all group stage matches", deadline:"2022-04-01"}, {title: "Final Stage", info: "Register the final matches", deadline:"2022-04-01"}];

function RegisterResultContainer(
  props: React.PropsWithChildren<PublishChangesRowProps>
) {

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        border: "1px solid #000",
        paddingRight: "260px",
        paddingLeft: "20px",
      }}
    >
      {props.children}
      <Drawer
        sx={{
          width: "240px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "240px",
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <h3 style={{textAlign:"center"}}>Your Progress Status</h3>
        <Divider />
        <List>
          {listItems.map((listItem, index) => (
            <ListItem button key={listItem.title}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Circle />
                <div style={{ display: "flex", flexDirection: "column", paddingLeft:"15px" }}>
                  <label>{listItem.title}</label>
                  <label style={{ fontSize: "12px" }}>
                    {listItem.info}
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    {`Deadline: ${listItem.deadline}`}
                  </label>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default RegisterResultContainer;
