import axios from "axios";
import { resolveModuleName } from "typescript";
import {
  ProfilePreview,
  Profile,
  AccountProfile,
  ProfileSettingsUpdate,
  Match,
  MatchResultBet,
  Team,
  GroupMatchStageMatches,
  GroupMatch,
  GroupTeamStanding,
  GroupStageResponseBody,
  Competition
} from "./types";
const apiBaseUrl = "https://api.vm-dream.com";
const instance = axios.create({
  baseURL: apiBaseUrl,
});

let authenticatedInstance = axios.create({ baseURL: apiBaseUrl });
if (localStorage.getItem("profiletool-auth-token") !== null) {
  authenticatedInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: localStorage.getItem("profiletool-auth-token"),
    },
  });
}

var useMock = false;
const fetchProfilesMockData = [
  {
    id: "123",
    profile_name: "Main Profile",
  },
  {
    id: "123",
    profile_name: "Nisch Profile",
  },
];

export var fetchGropuGamesRegister = () => {
  return new Promise<GroupStageResponseBody>((resolve, reject) => {
   
    authenticatedInstance
      .get("/v1/register-results/group-stage")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {});
  });
};

export var getCompetitionInvite = (competitionId: string) => {
  return new Promise<Competition>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/competitions/${competitionId}/invite`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

export var fetchProfiles = () => {
  return new Promise<ProfilePreview[]>((resolve, reject) => {
    if (useMock) {
      let profilePreviews: ProfilePreview[] = [];
      fetchProfilesMockData.map((jsonObj) => {
        const profilePreview: ProfilePreview = JSON.parse(
          JSON.stringify(jsonObj)
        );
        profilePreviews.push(profilePreview);
      });
      resolve(profilePreviews);
      return;
    }

    authenticatedInstance
      .get("/v1/profiles")
      .then(function (response) {
        let profilePreviews: ProfilePreview[] = [];
        response.data.map((jsonObj: any) => {
          const profilePreview: ProfilePreview = JSON.parse(
            JSON.stringify(jsonObj)
          );
          profilePreviews.push(profilePreview);
        });
        resolve(profilePreviews);
      })
      .catch(function (error) {
        // handle error
        reject(error);
      });
  });
};

export var fetchProfile = (profileId: string) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    if (useMock) {
      const jsonProfile = localStorage.getItem("123");
      if (jsonProfile !== null) {
        const profile = JSON.parse(jsonProfile);
        resolve(profile);
        return;
      }
      const newProfile: Profile = {
        id: "123",
        name: "Victor Keinander",
        pitch: "Mobile Dev",
        picture_base64_string: "",
        roles: [],
        skills: [],
        profile_name: "Konsultprofil",
        skill_blocks: [],
        side_projects: [],
        title: "",
        profile_contact_fields: [],
        ui_config: { dark_mode: false },
      };
      const accountProfile: AccountProfile = {
        profile: newProfile,
      };
      resolve(accountProfile);
      return;
    }

    authenticatedInstance
      .get("/v1/profiles/" + profileId)
      .then(function (response) {
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data)
        );
        profile.id = profileId;
        const accountProfile: AccountProfile = { profile: profile };
        resolve(accountProfile);
      })
      .catch(function (error) {
        console.log(error);
        reject();
      });
  });
};

export var fetchProfileFromLinkId = (profileLinkId: string) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    authenticatedInstance
      .get("/v1/p/" + profileLinkId)
      .then(function (response) {
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data)
        );
        const accountProfile: AccountProfile = { profile: profile };
        resolve(accountProfile);
      })
      .catch(function (error) {
        console.log(error);
        reject();
      });
  });
};

export const createProfile = () => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .post("/v1/profiles")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createProfileDraft = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .post("/v1/profiles/draft", { profile_id: profileId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProfile = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .delete("/v1/profiles/" + profileId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const publishProfileDraft = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .put("/v1/profiles/draft", { profile_id: profileId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};



export const updateProfile = (updatedProfile: Profile) => {
  return new Promise<Profile>((resolve, reject) => {
    if (useMock) {
      localStorage.setItem("123", JSON.stringify(updatedProfile));
      resolve(updatedProfile);
      return;
    }

    authenticatedInstance
      .put("/v1/profiles/" + updatedProfile.id, updatedProfile)
      .then(function (response) {
        const profile: Profile = JSON.parse(JSON.stringify(response.data));
        profile.id = updatedProfile.id;
        resolve(profile);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateAccountProfile = (profileId: string, name: string) => {
  return new Promise<ProfileSettingsUpdate>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/profiles/${profileId}/settings`, { id: profileId, name: name })
      .then(function (response) {
        const profileSettingsUpdate: ProfileSettingsUpdate = {
          id: response.data.id,
          name: response.data.name,
        };
        resolve(profileSettingsUpdate);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const duplicateAccountProfile = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/profiles/${profileId}/duplicate`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const signIn = (email: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      localStorage.setItem("333", JSON.stringify(email));
      resolve("");
      return;
    }

    instance
      .post("/v1/sign-in", { email: email })
      .then(function (response) {
        resolve("");
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const validateCode = (email: string, validationCode: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    instance
      .put("/v1/auth", { email: email, validation_code: validationCode })
      .then(function (response) {
        authenticatedInstance = axios.create({
          baseURL: apiBaseUrl,
          headers: {
            Authorization: response.data.token,
          },
        });
        localStorage.setItem("profiletool-auth-token", response.data.token);
        resolve("");
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const generateGroupStandings = (
  matches: GroupMatch[],
  bets: MatchResultBet[],
  groupName: string
) => {
  return new Promise<GroupTeamStanding>((resolve, reject) => {
    let requestBodyBets: MatchResultBet[] = [];
    const requestBodyMatches = matches.map((match) => {
      requestBodyBets.push(bets.filter(b => b.matchId === match.id)[0]);
      return {
        id: match.id,
        home_team_id: match.homeTeam.id,
        away_team_id: match.awayTeam.id,
      };
    });
  
    authenticatedInstance
      .post("/v1/generate-group-standings", {
        matches: requestBodyMatches,
        bets: requestBodyBets,
        groupName: groupName,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const createAuthenticatedInstance = (participantId: string) => {
  
  if (authenticatedInstance.defaults.headers.ParticipantID !== undefined) {
    return;
  }

  authenticatedInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      ParticipantID: participantId,
    },
  });

}

export const createParticipantForCompetition = (competitionId: string, email: string, username: string) => {
  return new Promise<string>((resolve, reject) => {
    const url = `/v1/competitions/${competitionId}/join`;

    instance
      .post(url, {email, username})
      .then(function (response) {
        authenticatedInstance = axios.create({
          baseURL: apiBaseUrl,
          headers: {
            ParticipantID: response.data,
          },
        });
        localStorage.setItem("vm-drommen-participant-id", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
