import { useEffect, useState } from "react";
import axios from "axios";
import { RouteComponentProps } from "@reach/router";
import {
  generateGroupStandings,
  fetchGropuGamesRegister,
  createAuthenticatedInstance,
} from "../requests";
import { Button } from "@mui/material";
import RegisterResultContainer from "../components/RegisterResultContainer";
import {
  Match,
  Team,
  MatchResultBet,
  GroupMatchStageMatches,
  GroupTeamStanding,
  GroupStageResponseBody,
  GroupMatch,
} from "../types";
import MatchListItem from "../components/MatchListItem";
interface RegisterGroupStageProps extends RouteComponentProps {
  participantId?: string;
}

function RegisterGroupStage({ participantId }: RegisterGroupStageProps) {
  const [groupMatchStageMatchesList, setGroupMatchStageMatchesList] = useState<
    GroupMatchStageMatches[]
  >([]);
  const [bets, setBets] = useState<MatchResultBet[]>([]);
  const [groupTeamStandings, setGroupTeamStandings] = useState<
    GroupTeamStanding[]
  >([]);

  useEffect(() => {
    if (localStorage.getItem("vm-drommen-participant-id") !== null) {
      createAuthenticatedInstance(
        localStorage.getItem("vm-drommen-participant-id")!
      );
      fetchGroupStageResultsData();
    }
  }, []);

  const fetchGroupStageResultsData = () => {
    var generateGroupStandingsPromises: Promise<GroupTeamStanding>[] = [];
    var generatedTeamStandings: GroupTeamStanding[] = [];
    fetchGropuGamesRegister()
      .then((groupStageRequestBody: GroupStageResponseBody) => {
        var bets: MatchResultBet[] = [];
        let teamStandings: GroupTeamStanding[] = [];
        var teams: Team[] = [];
        let groupStageMatches = groupStageRequestBody.groupStageMatches;

        for (var i = 0; i < groupStageMatches.length; i++) {
          groupStageMatches[i].sortedMatches = [];
          var shouldUpdateTeamStandings = false;

          const groupMatchStageMatches = groupStageMatches[i];

          let betsForGroup = [];

          teams = [];
          for (var j = 0; j < groupStageMatches[i].matches.length; j++) {
            const match = groupStageMatches[i].matches[j];
            if (j === 0 || j === 2 || j === 4) {
              groupStageMatches[i].sortedMatches.push([match]);
            } else {
              groupStageMatches[i].sortedMatches[
                groupStageMatches[i].sortedMatches.length - 1
              ].push(match);
            }

            if (
              groupStageRequestBody.matchResultBets.filter(
                (b) => b.matchId === match.id
              ).length > 0
            ) {
              shouldUpdateTeamStandings = true;
              const bet = groupStageRequestBody.matchResultBets.filter(
                (b) => b.matchId === match.id
              )[0];
              bets.push(bet);
              betsForGroup.push(bet);
            } else {
              const newBet: MatchResultBet = {
                matchId: match.id,
                homeTeamScore: 0,
                awayTeamScore: 0,
              };

              bets.push(newBet);
              betsForGroup.push(newBet);
            }

            teams = addIfNotExist(teams, match.homeTeam);
            teams = addIfNotExist(teams, match.awayTeam);
          }

          let newTeamStanding: GroupTeamStanding = {
            groupName: groupStageMatches[i].groupName,
            teams: teams,
          };
          teamStandings.push(newTeamStanding);

          if (shouldUpdateTeamStandings) {
            generateGroupStandingsPromises.push(
              generateGroupStandings(
                groupMatchStageMatches.matches,
                bets,
                groupMatchStageMatches.groupName
              )
            );
          }
        }

        setGroupMatchStageMatchesList(groupStageMatches);
        setBets(bets);
        generatedTeamStandings = [...teamStandings];
        setGroupTeamStandings(teamStandings);

        return axios.all(generateGroupStandingsPromises);
      })
      .then((response) => {

        response.map((groupTeamStanding: GroupTeamStanding) => {

          let currentGroupTeamStandings = generatedTeamStandings.filter(
            (gts) => gts.groupName !== groupTeamStanding.groupName
          );
          let previousTeamStanding = generatedTeamStandings.filter(
            (gts) => gts.groupName === groupTeamStanding.groupName
          )[0];

          //Map team names
          for (var i = 0; i < previousTeamStanding.teams.length; i++) {
            for (var j = 0; j < groupTeamStanding.teams.length; j++) {
              if (
                previousTeamStanding.teams[i].id ===
                groupTeamStanding.teams[j].id
              ) {
                groupTeamStanding.teams[j].name =
                  previousTeamStanding.teams[i].name;
              }
            }
          }

          currentGroupTeamStandings.push(groupTeamStanding);
          generatedTeamStandings = [...currentGroupTeamStandings];
          
        });
        setGroupTeamStandings([...generatedTeamStandings]);
      });
  };

  const generateTeamStandings = (
    matches: GroupMatch[],
    bets: MatchResultBet[],
    groupName: string
  ) => {
    generateGroupStandings(matches, bets, groupName)
      .then((groupTeamStanding) => {
        let currentGroupTeamStandings = groupTeamStandings.filter(
          (gts) => gts.groupName !== groupName
        );
        let previousTeamStanding = groupTeamStandings.filter(
          (gts) => gts.groupName === groupName
        )[0];

        //Map team names
        for (var i = 0; i < previousTeamStanding.teams.length; i++) {
          for (var j = 0; j < groupTeamStanding.teams.length; j++) {
            if (
              previousTeamStanding.teams[i].id === groupTeamStanding.teams[j].id
            ) {
              groupTeamStanding.teams[j].name =
                previousTeamStanding.teams[i].name;
            }
          }
        }

        currentGroupTeamStandings.push(groupTeamStanding);

        setGroupTeamStandings([...currentGroupTeamStandings]);
      })
      .catch((e) => {});
  };

  const addIfNotExist = (teams: Team[], team: Team): Team[] => {
    let newTeams = [...teams];
    if (teams.filter((t) => t.id === team.id).length === 0) {
      newTeams.push(team);
    }
    return newTeams;
  };

  const betForMatch = (matchId: string): MatchResultBet => {
    if (bets.filter((bet) => bet.matchId === matchId).length > 0) {
      return bets.filter((bet) => bet.matchId === matchId)[0];
    }
    let newMatchResultBet: MatchResultBet = {
      matchId: matchId,
      homeTeamScore: 0,
      awayTeamScore: 0,
    };
    return newMatchResultBet;
  };

  return (
    <RegisterResultContainer>
      <div style={{ width: "100%", height: "100%" }}>
        {groupMatchStageMatchesList.map((groupStageMatches, index) => {
          var teamStandingForGroup = groupTeamStandings.filter(
            (teamStanding) =>
              teamStanding.groupName === groupStageMatches.groupName
          )[0];
          if (!teamStandingForGroup) {
            return null;
          }
          return (
            <div className="group-result-container">
              <div
                style={{
                  flex: "3",
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "500px",
                }}
              >
                <h3 key={index}>{`Group ${groupStageMatches.groupName}`}</h3>
                {groupStageMatches.sortedMatches.map(
                  (sortedMatchArray, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "5px",
                          justifyContent: "space-between",
                          marginTop: "20px",
                        }}
                      >
                        {sortedMatchArray.map((match, i) => {
                          return (
                            <MatchListItem
                              match={match}
                              key={index}
                              bet={betForMatch(match.id)}
                              onUpdateBet={(updatedBet: MatchResultBet) => {
                                const betToUpdate = bets.filter(
                                  (b) => b.matchId === updatedBet.matchId
                                )[0];
                                const betToUpdateIndex =
                                  bets.indexOf(betToUpdate);
                                const newBet = {
                                  ...betToUpdate,
                                  homeTeamScore: updatedBet.homeTeamScore,
                                  awayTeamScore: updatedBet.awayTeamScore,
                                };
                                bets[betToUpdateIndex] = newBet;
                                setBets([...bets]);

                                generateGroupStandings(
                                  groupStageMatches.matches,
                                  bets,
                                  groupStageMatches.groupName
                                )
                                  .then((groupTeamStanding) => {
                                    let currentGroupTeamStandings =
                                      groupTeamStandings.filter(
                                        (gts) =>
                                          gts.groupName !==
                                          groupStageMatches.groupName
                                      );
                                    let previousTeamStanding =
                                      groupTeamStandings.filter(
                                        (gts) =>
                                          gts.groupName ===
                                          groupStageMatches.groupName
                                      )[0];

                                    //Map team names
                                    for (
                                      var i = 0;
                                      i < previousTeamStanding.teams.length;
                                      i++
                                    ) {
                                      for (
                                        var j = 0;
                                        j < groupTeamStanding.teams.length;
                                        j++
                                      ) {
                                        if (
                                          previousTeamStanding.teams[i].id ===
                                          groupTeamStanding.teams[j].id
                                        ) {
                                          groupTeamStanding.teams[j].name =
                                            previousTeamStanding.teams[i].name;
                                        }
                                      }
                                    }

                                    currentGroupTeamStandings.push(
                                      groupTeamStanding
                                    );

                                    setGroupTeamStandings([
                                      ...currentGroupTeamStandings,
                                    ]);
                                  })
                                  .catch((e) => {});
                              }}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                )}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <label style={{ fontSize: "12px", color: "#383B53" }}>
                    You can change your results anytime you want until the start
                    of the first game.
                  </label>
                </div>
              </div>

              <div
                style={{
                  flex: "3",
                  display: "flex",
                  maxWidth: "500px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginLeft: "25px",
                  paddingLeft:"25px",
                  paddingRight:"25px",
                  paddingTop: "80px",
                  backgroundColor:"#F4F5F6"
                }}
              >
                <table
                  style={{
                    width: "100%",
                    height: "30px",
                    textAlign: "center",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th>Position</th>
                    <th>Team</th>
                    <th>GD</th>
                    <th>GS</th>
                    <th>Points</th>
                  </tr>
                  {teamStandingForGroup.teams.map(
                    (groupStandingTeam, index) => {
                      let bottomBorderString =
                        index === 1 ? "2px solid green" : "2px solid #e3e3e3";
                      return (
                        <tr
                          style={{
                            height: "40px",
                            borderBottom: bottomBorderString,
                            width: "100%",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <label style={{ textAlign: "left" }}>
                              {groupStandingTeam.name}
                            </label>
                          </td>
                          <td>
                            <label>{groupStandingTeam.goalDifference}</label>
                          </td>
                          <td>
                            <label>{groupStandingTeam.goalsScored}</label>
                          </td>
                          <td>
                            <label>{groupStandingTeam.points}</label>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </table>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"20px"}}>
                  <div style={{width:"16px", height:"16px", backgroundColor:"green", borderRadius:"8px", marginRight:"10px"}}></div>
                  <label>Advancing from group</label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </RegisterResultContainer>
  );
}

export default RegisterGroupStage;

/*
  <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "1" }}>
          </div>
        </div>
        <div style={{ flex: "2" }}>
          {matches.map((match, index) => {
            const bet = bets.filter((bet) => bet.matchId === match.id)[0];
            return (
              <MatchListItem
                match={match}
                bet={bet}
                key={index}
                onUpdateBet={(updatedBet: MatchResultBet) => {
                  const betToUpdate = bets.filter(
                    (b) => b.matchId === updatedBet.matchId
                  )[0];
                  const betToUpdateIndex = bets.indexOf(betToUpdate);
                  const newBet = {
                    ...betToUpdate,
                    homeTeamScore: updatedBet.homeTeamScore,
                    awayTeamScore: updatedBet.awayTeamScore,
                  };
                  bets[betToUpdateIndex] = newBet;
                  setBets([...bets]);
                }}
              />
            );
          })}
          <button
            style={{ marginTop: "50px" }}
            onClick={() => {
              generateGroupStandings(matches, bets)
                .then((sortedTeams) => {
                  let mappedTeams: Team[] = sortedTeams.map((sortedTeam) => {
                
                    let mapppedTeam: Team = {
                      ...sortedTeam,
                      name: teams.filter((t) => t.id === sortedTeam.id)[0].name,
                    };
                    return mapppedTeam;
                  });
                
                  setGroupStandingTeams(mappedTeams);
                })
                .catch((e) => {
                });
            }}
          >
            Calculate group standings
          </button>
        </div>
        <div style={{ flex: "2" }}>
          <table>
            <tr>
              <th>Position</th>
              <th>Team</th>
              <th>GD</th>
              <th>GS</th>
              <th>Points</th>
            </tr>
            {groupStandingTeams.map((groupStandingTeam, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <label>{groupStandingTeam.name}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.goalDifference}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.goalsScored}</label>
                  </td>
                  <td>
                    <label>{groupStandingTeam.points}</label>
                  </td>
                </tr>
              );
            })}
          </table>
          <h4>Currrent sorting</h4>
          <ol>
            <li>
            Number of points
            </li>
            <li>
            2. Versus
            </li>
          
          
            <ul>
              <li>
              Higher number of points obtained in the matches played between the teams in question;
              </li>
              <li>
              Superior goal difference resulting from the matches played between the teams in question;
              </li>
              <li>
              Higher number of goals scored in the matches played between the teams in question;
              </li>
            </ul>
            </ol>
        </div>
*/
